<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="keyWords" placeholder="群名称" style="width: 200px;"></el-input>
			</div>
			<!-- 活动状态 -->
			<div class="filter-item">
				<label class="label">时间筛选:</label>
				<el-date-picker type="datetime" placeholder="选择日期" v-model="startTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading='loading' @sort-change="sortChange">
				<el-table-column prop="Name" label="群名称" width="250"> 
					<template slot-scope="scope">
            <div class="dialog-name-content">
							<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
            </div>
					</template>
        </el-table-column>
				<el-table-column label="群主">
					<template slot-scope="scope">
						<span>{{scope.row.GroupManagerName}}</span>
						<span v-if="scope.row.GroupManagerPhone">（{{scope.row.GroupManagerPhone}}）</span>
					</template>
				</el-table-column>
				<el-table-column prop="SellMoney" sortable>
          <template slot="header" slot-scope="scope">
            <span>群总业绩</span>
            <el-tooltip class="item" effect="dark" content="绑定群的成员，支付成功的订单数量" placement="top-start">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
				<el-table-column prop="SellOrderCount" sortable>
          <template slot="header" slot-scope="scope">
            <span>群总订单</span>
            <el-tooltip class="item" effect="dark" content="绑定群的成员，订单支付金额，包含运费" placement="top-start">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
				<el-table-column prop="GroupMemberCount" label="群总人数" sortable></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scop">
            <div class="table-button" @click="showMember(scop.row.Id)">群成员</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

		<el-dialog title="群成员" :visible.sync="memberVisible" class="member-dialog" width="1000px">
			<div class="dialog-body">
				<div class="filter-container">
					<div class="filter-item">
						<label class="label">关键字: </label>
						<el-input v-model="member.keyWords" placeholder="成员名称" style="width: 200px;"></el-input>
					</div>
					<div class="filter-item">
						<label class="label">时间筛选:</label>
						<el-date-picker type="datetime" placeholder="选择日期" v-model="member.startTime" style="width: 200px;" value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
						<el-date-picker type="datetime" placeholder="选择日期" v-model="member.endTime" style="width: 200px;" value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
						<el-button type="primary" style="margin-left:10px;" @click="handleFilter1">查询</el-button>
					</div>
				</div>
				<el-table :data="memberList" style="width: 100%" v-loading='memberLoading' @sort-change="memberSortChange">
					<el-table-column prop="Name" label="成员姓名" > 
						<template slot-scope="scope">
							<div class="dialog-name-content" width="300">
								<img :src='scope.row.WxHeadUrl ? scope.row.WxHeadUrl: defaultImg' />
								<div class="name">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="SellOrderCount" label="订单数" sortable></el-table-column>
					<el-table-column prop="SellMoney" label="业绩" sortable></el-table-column>
					<el-table-column prop="AddTime" label="入群时间"></el-table-column>
				</el-table>
				<el-pagination v-if="memberPage.total" style="margin-top:20px;float:right;" @size-change="handleMemberSizeChange" @current-change="handleMemberCurrentChange"
				:current-page="memberPage.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="memberPage.size" layout="total, sizes, prev, pager, next, jumper"
				:total="memberPage.total">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		groupList,
		groupMemberList,
	} from '@/api/goods'
	import config from '@/config/index'

	export default {
		data() {
			return {
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				exportUrl: config.EXPORT_URL,
				defaultImg: 'https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png',
				loading: false,
        keyWords: '',
        startTime: '',
        endTime: '',
				tableData: [],
				page: {
					total: 0,
					current: 1,
					size: 20
        },
        OrderBy:'',
        IsAsc:'',

				memberVisible: false,
				memberLoading:false,
				memberList: [],
				member: {
					orderBy:'',
					isAsc:'',
					id: null,
					keyWords: '',
					startTime: '',
					endTime: '',
				},
				memberPage: {
					total: 0,
					current: 1,
					size: 10
				},
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getList() {
				this.loading = true;
				try {
					let data = {
            Keywords: this.keyWords,
            StartTime: this.startTime, 
            EndTime: this.endTime, 
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
            OrderBy:this.OrderBy,
            IsAsc: this.IsAsc
					}
					let result = await groupList(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
      },
			sortChange(column, prop, order) {
				if (column.order == "ascending") {
					this.IsAsc = true
					this.OrderBy = column.prop
				} else if(column.order == "descending") {
					this.IsAsc = false
					this.OrderBy = column.prop
				} else {
					this.IsAsc = ''
					this.OrderBy = ''
				}
				this.page.current = 1;
				this.getList()
			},
			exportFun() {
				let url = this.exportUrl + '/pc/group/export?' +
					'KeyWords=' + this.keyWords  +
					'&StartTime=' + (this.startTime ? this.startTime : '') +
					'&EndTime=' + (this.endTime ? this.endTime : '')
				window.open(url);
			},
			handleFilter() {
				this.page.current = 1;
				this.getList()
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
			showMember(id) {
				this.member.id = id
				this.memberPage.current = 1;
				this.member.keyWords= ''
        this.member.startTime= '' 
        this.member.endTime= '' 
				this.getMemberList()
			},
			handleSearchMember() {
				this.memberPage.current = 1;
				this.getMemberList()
			},
			async getMemberList() {
				this.memberVisible = true
				this.memberLoading= true
				try {
					let data = {
            Id: this.member.id, 
            Keywords: this.member.keyWords,
            StartTime: this.member.startTime, 
            EndTime: this.member.endTime,  
						Skip: (this.memberPage.current - 1) * this.memberPage.size,
						Take: this.memberPage.size,
            OrderBy:this.member.orderBy,
            IsAsc: this.member.isAsc
					}
					console.log('123123',this.member,data)
					let result = await groupMemberList(data);
					this.memberPage.total = result.Result.Total;
					this.memberList = result.Result.Results || [];
				} catch (error) {
				} finally {
					this.memberLoading = false;
				}
			},
			handleFilter1() {
				this.memberPage.current = 1;
				this.getMemberList()
			},
			memberSortChange(column, prop, order) {
				if (column.order == "ascending") {
					this.member.isAsc = true
					this.member.orderBy = column.prop
				} else if(column.order == "descending") {
					this.member.isAsc = false
					this.member.orderBy = column.prop
				} else {
					this.member.isAsc = ''
					this.member.orderBy = ''
				}
				this.memberPage.current = 1;
				this.getMemberList()
			},
			// 切换显示条数
			handleMemberSizeChange(val) {
				this.memberPage.size = val;
				this.getMemberList();
			},
			// 翻页
			handleMemberCurrentChange(val) {
				this.memberPage.current = val;
				this.getMemberList();
			},

		}
	}
</script>

<style lang="less" scoped>
	.app-container {
    background-color: #fff;
    .el-icon-warning {
      margin-left: 5px;
    }

    .dialog-name-content {
			
			margin: 10px 0;
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}
			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
    }
	}
	.dialog-body {
		overflow: hidden;
	}
	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}
</style>
